.slick-slider.simple {
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .slick-center > div {
    transition: 0.3s;
    transform: scale(1.35);
  }

  .slick-slide > div {
    margin: 0 58px;
    height: 170px;
    background-color: #ebf4ff;
    border-radius: 1rem;
  }

  .slick-slide > div > div {
    height: 100%;
    width: 100%;
  }

  .slick-slide > div > div > a {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 0.875rem;
  }

  .slick-slider.center
    > .slick-list
    > .slick-track
    > div.slick-slide
    > div
    > div
    > a
    > img {
    height: 100%;
  }

  /* Edge */
  .slick-slider.center {
    /* display: flex !important;
  flex-direction: column !important; */
    height: 14rem !important;
  }

  .slick-slider.simple {
    height: 22rem;
    margin-top: -2rem;
    padding: 0 1rem;
  }

  .slick-slider.simple
    > .slick-list
    > .slick-track
    > div.slick-slide
    > div
    > div
    > a
    > img {
    height: 22rem;
  }

  .slick-slider
    > .slick-list
    > .slick-track
    > div.slick-slide
    > div
    > div
    > a
    > img {
    object-fit: fill;
    width: 100%;
    border-radius: 0.875rem;
  }
}

.slick-list {
  overflow: unset !important;
  overflow-x: clip !important;
}

.single-banner {
  background-color: #ebf4ff;
  border-radius: 1rem;
}

/* .slick-track {
  transform: translate3d(-1243px, 0px, 0px) !important;
  display: flex !important;
  gap: 8rem !important;
  align-items: center !important;
}

.slick-slide.slick-active {
  transform: scale(1.5) !important;
} */

/* .slick-dots {
  margin-top: 5rem !important;
} */

/* @media only screen and (min-width: 768px) { */
/* .slick-slider {
    width: 100%;
    overflow: hidden;
  } */
/* .slick-list {
    position: relative;
    display: block !important;
    width: 100%;
  }
  .slick-slide div {
    width: auto;
    margin-right: 10px;
  }
  .slick-slide img {
    width: calc(100% - 10px) !important;
  } */

/* } */

@media only screen and (max-width: 768px) {
  .slick-slider.simple {
    height: 7.5rem;
  }

  .slick-slide > div {
    height: 180px;
    margin: 0.75rem 0.875rem;
    background-color: #ebf4ff;
    border-radius: 1rem;
  }

  .slick-slide > div > div {
    height: 100%;
  }

  .slick-slider.center
    > .slick-list
    > .slick-track
    > div.slick-slide
    > div
    > div
    > a
    > img {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
  }
}

.slick-dots li button:before {
  font-size: 0.75rem;
}

.slick-dots li.slick-active {
  width: initial;
}

.slick-dots li {
  width: 12px;
}

.slick-dots li button:before {
  content: '';
}

.slick-dots li.slick-active button {
  transition: 0.3s;
  background-color: #0b3568;
  border-radius: 10px;
  width: 60px;
  height: 10px;
}

.slick-dots li button {
  transition: 0.3s;
  background-color: rgba(11, 53, 104, 0.5);
  border-radius: 10px;
  width: 10px;
  height: 10px;
}
